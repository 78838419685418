<template>
	<div>
		<Hero image="../img/15.jpg" />
		<main>
			<div class="container">
				<Heading title="Admissions" />
				<div class="content">
					<div class="row">
						<div class="col-md-8">
							
						</div>
						<div class="col-md-4">
							<MenuAdmissions />
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	data() {
		return {};
	},
	components: {},
	mounted(){},
};
</script>
